<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Link rel="stylesheet" href="https://use.typekit.net/znl1pjo.css" />
    </Head>
    <Body>
      <slot />
    </Body>
  </Html>
</template>

<script setup lang="ts">

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});
</script>
